import "../components_styling/Home.css";
import img_banner from "../media/images/team-hands-linked-together.jpg";
import img_mission from "../media/images/pexels-brett-sayles-3633711.jpg";
import img_choral from "../media/images/pexels-tima-miroshnichenko-6860500.jpg";
import img_spiritual_help from "../media/images/pexels-pixabay-45842.jpg";
import img_volunteering from "../media/images/joel-muniz-3k3l2brxmwQ-unsplash.jpg";
import { FiArrowDownCircle } from "react-icons/fi";
import { Link } from "react-router-dom";

function HomeMobile() {
  //Scroll down
  function scrollDown() {
    document.getElementById("div_components_mission").scrollIntoView();
  }

  //Banner image
  const banner = {
    backgroundImage: `url(${img_banner})`,
    height: "100vh",
    width: "100%",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
  };

  return (
    <div className="div_components">
      <div className="div_components_banner">
        <div style={banner}>
          <h1>Bienvenue au Centre d’espoir de Lanaudière</h1>
          <FiArrowDownCircle
            onClick={scrollDown}
            id="div_components_scroll_down_btn"
          />
        </div>
        {/* Pour afficher une video comme banner */}
        {/* <video id="video" muted autoplay loop>
                <source src="../media/video/test.mp4" type="video/mp4">
                Your browser does not support the video tag.
            </video> */}
      </div>

      <div id="div_components_mission" className="div_components_mission">
        <div className="div_components_mission_txt">
          <Link to="/Mission" style={{ textDecoration: "none" }}>
            <h2>Notre mission</h2>
          </Link>
          <p>
          La raison d’être du Centre d’espoir de Lanaudière est d’aimer le Dieu
          créateur de l’univers, de proclamer le message des trois anges
          d'Apocalypse 14 : 6-12 et de servir son prochain.
          </p>
        </div>
        <div className="div_components_mission_img">
          <Link to="/Mission" style={{ textDecoration: "none" }}>
            <img src={img_mission} alt="mission.img" />
          </Link>
        </div>
      </div>

      <div className="div_components_choral">
        <div className="div_components_choral_txt">
          <Link to="/Choral" style={{ textDecoration: "none" }}>
            <h2>The Bridge Of Hope</h2>
          </Link>
          <p>
            La chorale des jeunes et les artistes du Centre d’espoir de
            Lanaudière se donnent pour objectif de lutter contre l’isolement et
            la solitude par la musique.
          </p>
        </div>
        <div className="div_components_choral_img">
          <Link to="/Choral" style={{ textDecoration: "none" }}>
            <img src={img_choral} alt="choral.img" />
          </Link>
        </div>
      </div>

      <div className="div_components_spiritual_help">
        <div className="div_components_spiritual_help_txt">
          <Link
            to="/SpiritualHelp"
            style={{ textDecoration: "none", color: "white" }}
          >
            <h2>Accompagnement spirituel</h2>
          </Link>
          <p>
            L’être Humain n’a pas été crée pour vivre seul. Le centre d’espoir
            de Lanaudière est là pour vous accompagner dans votre cheminement
            spirituel
          </p>
        </div>
        <div className="div_components_spiritual_help_img">
          <Link
            to="/SpiritualHelp"
            style={{ textDecoration: "none", color: "white" }}
          >
            <img src={img_spiritual_help} alt="spiritual_help.img" />
          </Link>
        </div>
      </div>

      <div className="div_components_volunteering">
        <div className="div_components_volunteering_txt">
          <Link
            to="/Volunteering"
            style={{ textDecoration: "none", color: "white" }}
          >
            <h2>Bénévolat divers</h2>
          </Link>
          <p>
            Le Centre d’espoir de Lanaudière offre un service de bénévolat
            divers. Nous sommes disponibles pour vous aider que ce soit pour
            vous offrir un transport, tondre la pelouse, déneiger votre entrée
            ou vous aider à des travaux.
          </p>
        </div>
        <div className="div_components_volunteering_img">
          <Link
            to="/Volunteering"
            style={{ textDecoration: "none", color: "white" }}
          >
            <img src={img_volunteering} alt="volunteering.img" />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default HomeMobile;
