import "../components_styling/PageYouth.css";
import img_youth from "../media/images/aedrian-ECt01A5HZtw-unsplash.jpg";
import img_choral from "../media/images/pexels-tima-miroshnichenko-6860500.jpg";
import img_zoom_meeting from "../media/images/chris-montgomery-smgTvepind4-unsplash.jpg";
import img_instagram from "../media/images/alexander-shatov-71Qk8ODIBko-unsplash.jpg";

function PageYouthDesktop() {
  return (
    <div className="div_page_youth">
      <div className="div_page_youth_1">
        <h2>Les jeunes</h2>
        <p>
          Bienvenue sur la page réservée à la jeunesse. En tant que disciples de
          Dieu, notre but est d’apporter de l’espoir aux autres tout en faisant
          partie de la jeunesse de l’église. Nous espérons apporter une vague de
          fraîcheur dans nos communautés et ainsi faire découvrir Dieu à chacun.
          Nous promouvons l’esprit d’équipe ainsi que la différence de chacun.
          Ensemble, nous formons la jeunesse du centre d’espoir de Lanaudière.
        </p>
      </div>
      <img src={img_youth} alt="youth.img" id="div_page_youth_img_youth"></img>
      <div className="div_page_youth_2">
        <div className="div_page_youth_2_img">
          <img src={img_choral} alt="choral.img"></img>
        </div>
        <div className="div_page_youth_2_txt">
          <h2>La chorale : The Bridge Of Hope</h2>
          <p>
            Le but de notre chorale est d’apporter du réconfort, de la joie et
            de l’amour tout en glorifiant Dieu par nos chants. Nous comprenons
            que parfois la vie n’est pas facile. C’est pour cela que la chorale
            The Bridge Of Hope se tient de donner de l’espoir à chacun. La
            chorale est composée de jeunes chanteurs et chanteuses talentueux
            qui performent régulièrement.
          </p>
        </div>
      </div>
      <div className="div_page_youth_3">
        <div className="div_page_youth_3_txt">
          <h2>Réunion sur zoom</h2>
          <p>
            Depuis quelque temps, les rassemblements ne sont pas toujours
            autorisés. Ainsi, nous vous proposons la plateforme Zoom pour
            assister à des rencontres entre jeunes pour discuter de sujets
            divers à chaque rencontre. Nous nous rassemblons plusieurs fois par
            mois pour prier, parler et nous amuser.
          </p>
        </div>
        <div className="div_page_youth_3_img">
          <img src={img_zoom_meeting} alt="zoom_meeting.img"></img>
        </div>
      </div>
      <div className="div_page_youth_4">
        <div className="div_page_youth_4_img">
          <img src={img_instagram} alt="instagram.img"></img>
        </div>
        <div className="div_page_youth_4_txt">
          <h2>Notre compte Instagram</h2>
          <p>
            Les réseaux sociaux prennent de plus en plus de place dans la vie
            des jeunes. C’est pour ça que nous vous invitons à venir nous suivre
            sur Instagram. Sur notre page, nous vous proposons des conseils, des
            versets et c’est ici que les informations sont les plus postées.
          </p>
        </div>
      </div>
    </div>
  );
}

export default PageYouthDesktop;
