import React, { useEffect } from "react";
import "../components_styling/PageChoral.css";
import Form from "./Form";
import img_choral from "../media/images/choir.jpg";
import { FiChevronDown } from "react-icons/fi";

function PageChoral() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //Scroll down
  function scrollDown() {
    document.getElementById("div_form").scrollIntoView();
  }

  return (
    <div className="div_page_choral">
      <div className="div_page_choral_1">
        <h2>La chorale : The Bridge Of Hope</h2>
        <p>
          La chorale The Bridge Of Hope et les artistes du Centre d’espoir de
          Lanaudière se donnent pour objectif de lutter contre l’isolement et la
          solitude par la musique. Nous offrons nos services principalement dans
          les résidences pour ainés. Nos activités sociales et concerts
          rassemblent les gens et créent des liens.
        </p>
      </div>
      <div className="div_page_choral_img">
        <img src={img_choral} alt="choral.img"></img>
      </div>
      <div className="div_page_choral_2">
        <FiChevronDown
          id="div_page_choral_scroll_down_btn"
          onClick={scrollDown}
        />
      </div>

      <div className="div_page_choral_3">
        <h2>Demande pour la chorale</h2>
        <Form subject="Demande pour la chorale" />
      </div>
    </div>
  );
}

export default PageChoral;
