import "../components_styling/NavDesktop.css";
import logo from "../media/images/church_logo_2.png";
import { Link } from "react-router-dom";
import { FiChevronDown } from "react-icons/fi";
import { FiChevronUp } from "react-icons/fi";
import React, { useState } from "react";

function NavDesktop() {
  /*
  * Dropdown menu
  */
  function NavItems(props) {
    const [menu, setMenu] = useState(false);

    function Chevron() {
      return menu ? <FiChevronUp /> : <FiChevronDown />;
    }
    return (
      <div
        className={menu ? `${props.classe} active` : `${props.classe} out`}
        onMouseEnter={() => setMenu(true)}
        onMouseLeave={() => setMenu(false)}
      >
        <div
          className={
            menu ? `${props.classe}_div active` : `${props.classe}_div`
          }
        >
          <h2
            onClick={() => {
              setMenu(!menu);
            }}
          >
            {props.title}
          </h2>
          <Chevron />
        </div>

        {menu && props.children}
      </div>
    );
  }

  return (
    <div className="div_nav">
      <nav className="nav_desktop">
        <div className="div_nav_home">
          <Link to="/" style={{ textDecoration: "none" }}>
            <img src={logo} alt="logo" />
          </Link>
        </div>
        <div className="div_nav_mission">
          <Link to="/Mission" style={{ textDecoration: "none" }}>
            <h2>Mission</h2>
          </Link>
        </div>
        <div className="div_nav_news">
          <Link to="News" style={{ textDecoration: "none" }}>
            <h2>Annonces</h2>
          </Link>
        </div>
        <div className="div_nav_choir">
          <Link to="/Choral" style={{ textDecoration: "none" }}>
            <h2>Chorale</h2>
          </Link>
        </div>
        <div className="div_nav_youth">
          <Link to="/Youth" style={{ textDecoration: "none" }}>
            <h2>Jeunesse</h2>
          </Link>
        </div>
        {/* <div className="div_nav_donation">
          <Link to="/Donation" style={{ textDecoration: "none" }}>
            <h2>Donation</h2>
          </Link>
        </div> */}
        <NavItems classe="div_nav_service" title="Services">
          <div className="div_nav_service_items">
            <Link
              to="/SpiritualHelp"
              style={{ textDecoration: "none", color: "white" }}
            >
              <h3>Accompagnement spirituel</h3>
            </Link>
            <Link
              to="/Volunteering"
              style={{ textDecoration: "none", color: "white" }}
            >
              <h3>Bénévolat</h3>
            </Link>
            <Link
              to="/Homework"
              style={{ textDecoration: "none", color: "white" }}
            >
              <h3>Aide au devoir</h3>
            </Link>
            <Link
              to="/HelpSenior"
              style={{ textDecoration: "none", color: "white" }}
            >
              {/* <h3>Aide aux ainés</h3> */}
              <h3>Visites d'amitié</h3>
            </Link>
            <Link
              to="/WalkingClub"
              style={{ textDecoration: "none", color: "white" }}
            >
              <h3>Club de marche</h3>
            </Link>
            <Link
              to="/TaxClinic"
              style={{ textDecoration: "none", color: "white" }}
            >
              <h3>Clinique d’impôt</h3>
            </Link>
          </div>
        </NavItems>

        <NavItems classe="div_nav_contacts" title="Nous joindre">
          <div className="div_nav_contacts_items">
            <a
              href="https://www.youtube.com/channel/UCG4lP18rHWu-suRoWeb0QRw/featured"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none", color: "white" }}
            >
              <h3>Youtube</h3>
            </a>
{/*             <a style={{ textDecoration: "none", color: "white" }}>
              <h3>Facebook</h3>
            </a> */}
            <a
              href="https://instagram.com/_bridgeofhope_?utm_medium=copy_link"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none", color: "white" }}
            >
              <h3>Instagram</h3>
            </a>
            <Link
              to="/Contact"
              style={{ textDecoration: "none", color: "white" }}
            >
              <h3>Nous contacter</h3>
            </Link>
          </div>
        </NavItems>
      </nav>
    </div>
  );
}

export default NavDesktop;
