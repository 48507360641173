import React, { useEffect } from "react";
import "../components_styling/PageNews.css";
import img_sabbat from "../media/images/aaron-burden-UIib0bAvWfs-unsplash.jpg";
/* import img_choral from "../media/images/david-beale-gOsGgt4olNs-unsplash.jpg";*/
import img_reunion_priere from "../media/images/pedro-lima-HtwsbbClBOs-unsplash.jpg";
import img_croisade from "../media/images/IMG-20220516-WA0020[8286].jpg";

function PageNews() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="div_page_news">
      <h2>Événement hebdomadaire</h2>
      <div className="div_page_news_1">
        <div className="div_page_news_1_event">
          <img src={img_sabbat} alt="sabbat.img"></img>
          <h3>Culte d'adoration le samedi</h3>
          <p>Tous les samedis | De 10h00 à 12h30</p>
        </div>

        <div className="div_page_news_1_event">
          <img src={img_reunion_priere} alt="reunion_priere.img"></img>
          <h3>Réunion de prière</h3>
          <p>Tous les mercredis | De 19h00 à 20h00</p>
        </div>
      </div>
      <h2>Événement à venir</h2>
      <div className="div_page_news_2">
        <p style={{ textAlign: "center" }}>
          Aucun évènement à venir pour le moment
        </p> 
{/*         <div className="div_page_news_2_event">
          <img src={img_croisade} alt="img_croisade.img"></img>
          <div className="div_page_news_2_event_txt">
            <h2>L'espoir de l'humanité</h2>
            <div className="div_page_news_2_event_txt_1">
              <h3>Temps & Lieu</h3>
              <p>du 09 au 30 juillet 2022 | Chaque soir de 19h00 à 20h30</p>
              <p>1364 Rue Notre-Dame, Repentigny, QC J5Y 3X1</p>
            </div>
            <div className="div_page_news_2_event_txt_2">
              <h3>À propos de l'événement</h3>
              <p>
                Le centre d'espoir de Lanaudière vous présente: L'espoir de
                l'humanité. Une série de conférences présentées par le
                conférencier Past. Georges Ulysse, qui répondra à la question
                suivante. Les politiciens et scientifiques ont essuyé de
                lamentables échecs dans leurs stratégies pour protéger le monde.
                Alors, vers qui se tourner ?
              </p>
            </div>
          </div>
        </div> */}
        {/* <div className="div_page_news_2_event">
          <img src={img_news_1} alt="img_news_1.img"></img>
          <div className="div_page_news_2_event_txt">
            <h2>Culte chanté</h2>
            <div className="div_page_news_2_event_txt_1">
              <h3>Temps & Lieu</h3>
              <p>04 Décembre 2021 | De 10h00 à 12h30</p>
              <p>1364 Rue Notre-Dame, Repentigny, QC J5Y 3X1</p>
            </div>
            <div className="div_page_news_2_event_txt_2">
              <h3>À propos de l'événement</h3>
              <p>
                Venez en grand nombre le samedi 4 décembre à 10h00 pour nous
                joindre lors d'un culte d'adoration spéciale.
              </p>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default PageNews;
