import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navigator from "./components/Navigator";
import Footer from "./components/Footer";
import Home from "./components/Home";
import PageMission from "./components/PageMission";
import PageHomework from "./components/PageHomework";
import PageVolunteering from "./components/PageVolunteering";
import PageHelpSenior from "./components/PageHelpSenior";
import PageSpiritualHelp from "./components/PageSpiritualHelp";
import PageTaxClinic from "./components/PageTaxClinic";
import PageWalkingClub from "./components/PageWalkingClub";
import PageChoral from "./components/PageChoral";
import PageYouth from "./components/PageYouth";
// import PageDonation from "./components/PageDonation";
import PageNews from "./components/PageNews";
import PageContact from "./components/PageContact";

function App() {

  return (
    <Router>
      <div className="App">
        <Navigator />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Mission" element={<PageMission />} />
          <Route path="/Homework" element={<PageHomework />} />
          <Route path="/Volunteering" element={<PageVolunteering />} />
          <Route path="/HelpSenior" element={<PageHelpSenior />} />
          <Route path="/SpiritualHelp" element={<PageSpiritualHelp />} />
          <Route path="/TaxClinic" element={<PageTaxClinic />} />
          <Route path="/WalkingClub" element={<PageWalkingClub />} />
          <Route path="/Choral" element={<PageChoral />} />
          <Route path="/Youth" element={<PageYouth />} />
          {/* <Route path="/Donation" element={<PageDonation />} /> */}
          <Route path="/News" element={<PageNews />} />
          <Route path="/Contact" element={<PageContact />} />
          <Route path="*" element={<Home />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
