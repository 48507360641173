import React, { useEffect } from "react";
import "../components_styling/PageHelpSenior.css";
import Form from "./Form";
import img_help_senior from "../media/images/pexels-rodnae-productions-6647037_2.jpg";
import { FiChevronDown } from "react-icons/fi";

function PageHelpSenior() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //Scroll down
  function scrollDown() {
    document.getElementById("div_form").scrollIntoView();
  }

  return (
    <div className="div_page_help_senior">
      <div className="div_page_help_senior_1">
        <h2>Visites d'amitié</h2>
{/*         <p>
          Les ainés ont toujours besoin d’attention et de l’aide. Avoir du répit
          bien mérité peut les aider à avoir une bonne journée et s’épanouir.
          Que ce soit pour de l’aide domestique, des soins à la personne ou de
          l’accompagnement, le Centre d’espoir de Lanaudière est là pour vous
          aider.
        </p> */}
        <p>Afin de briser l'isolement des personnes âgées, le Centre D'espoir de Lanaudière offre un service téléphonique - visites d'amitié, qui vise à rassurer, divertir et informer les personnes vivantes seules à domicile ou en résidence privée ou en CHSLD. Il permet de briser l'isolement social et de créer une relation amicale.
Passons un petit moment ensemble à échanger.</p>
      </div>
      <div className="div_page_help_senior_img">
        <img src={img_help_senior} alt="help_senior.img"></img>
      </div>
      <div className="div_page_help_senior_2">
        <FiChevronDown
          id="div_page_help_senior_scroll_down_btn"
          onClick={scrollDown}
        />
      </div>

      <div className="div_page_help_senior_3">
        <h2>Demande d'aide pour ainé</h2>
        <Form subject="Demande d'aide pour ainé" />
      </div>
    </div>
  );
}

export default PageHelpSenior;
