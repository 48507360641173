import React, { useEffect } from "react";
import "../components_styling/PageWalkingClub.css";
import Form from "./Form";
import img_walking_club from "../media/images/luke-porter-NEqEC7qa9FM-unsplash.jpg";
import { FiChevronDown } from "react-icons/fi";

function PageWalkingClub() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //Scroll down
  function scrollDown() {
    document.getElementById("div_form").scrollIntoView();
  }

  return (
    <div className="div_page_walking_club">
      <div className="div_page_walking_club_1">
        <h2>Club de marche</h2>
        <p>
          On n’a pas souvent le goût de marcher tout seul ou l’on manque de
          motivation. Le Centre d’espoir de Lanaudière vient en aide à tous ceux
          qui aiment la marche, à se joindre à notre club de marche, notre
          programme vous permettra de maintenir une bonne forme physique tout en
          profitant de la nature. La marche permet de faire travailler les
          articulations, les muscles, les tendons ainsi que le système
          cardiorespiratoire c’est un excellent moyen de se dépenser et de
          garder une bonne forme physique.
        </p>
      </div>
      <div className="div_page_walking_club_img">
        <img src={img_walking_club} alt="walking_club.img"></img>
      </div>
      <div className="div_page_walking_club_2">
        <FiChevronDown
          id="div_page_walking_club_scroll_down_btn"
          onClick={scrollDown}
        />
      </div>

      <div className="div_page_walking_club_3">
        <h2>Demande pour le club de marche</h2>
        <Form subject="Demande pour le club de marche" />
      </div>
    </div>
  );
}

export default PageWalkingClub;
