import "../components_styling/Footer.css";
/* import img_facebook from "../media/images/facebook-f-brands.svg";*/
import img_youtube from "../media/images/youtube-brands.svg";
import img_instagram from "../media/images/instagram-brands.svg";

function Footer() {
  return (
    <footer className="footer">
      <div className="div_footer_social">
{/*         <img src={img_facebook} alt="facebook.img" />
 */}        <a
          href="https://www.youtube.com/channel/UCG4lP18rHWu-suRoWeb0QRw/featured"
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: "none", color: "white" }}
        >
          <img src={img_youtube} alt="youtube.img" />
        </a>
        <a
          href="https://instagram.com/_bridgeofhope_?utm_medium=copy_link"
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: "none", color: "white" }}
        >
          <img src={img_instagram} alt="instagram.img" />
        </a>
      </div>
      <p id="copyRight">
        Centre d'espoir de Lanaudière 2022 Tous droits réservés
      </p>
    </footer>
  );
}

export default Footer;
