import React, { useEffect } from "react";
import "../components_styling/PageSpiritualHelp.css";
import Form from "./Form";
import img_spiritual_help from "../media/images/pexels-marcus-aurelius-6787787_2.jpg";
import { FiChevronDown } from "react-icons/fi";

function PageSpiritualHelp() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //Scroll down
  function scrollDown() {
    document.getElementById("div_form").scrollIntoView();
  }

  return (
    <div className="div_page_spiritual_help">
      <div className="div_page_spiritual_help_1">
        <h2>Accompagnement spirituel</h2>
        <p>
          Il y a quelques années, des savants de la Faculté de médecine de
          l’université de Pittsburgh publièrent les résultats d’une recherche,
          qui révéla que l’assistance hebdomadaire aux services religieux
          pouvait ajouter trois années à la vie. D’après le chercheur
          responsable de l’étude Daniel Hall, "Appartenir à une communauté comme
          un groupe religieux a un effet positif sur la santé physique. L’être
          humain n’a pas été créé pour vivre seul". Le Centre d’espoir de
          Lanaudière est là pour vous accompagner dans votre cheminement
          spirituel.
        </p>
      </div>
      <div className="div_page_spiritual_help_img">
        <img src={img_spiritual_help} alt="spiritual_help.img"></img>
      </div>
      <div className="div_page_spiritual_help_2">
        <FiChevronDown
          id="div_page_spiritual_help_scroll_down_btn"
          onClick={scrollDown}
        />
      </div>

      <div className="div_page_spiritual_help_3">
        <h2>Demande d'accompagnement spirituel</h2>
        <Form subject="Demande d'accompagnement spirituel" />
      </div>
    </div>
  );
}

export default PageSpiritualHelp;
