import React, { useEffect } from "react";
import "../components_styling/PageContact.css";
import Form from "./Form";

function PageContact() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="div_page_contact">
      <div className="div_page_choral_1">
        <div className="div_page_choral_1_info">
          <div>
            <h3>Notre établissement</h3>
            <p>1364 Rue Notre-Dame, Repentigny, QC J5Y 3X1</p>
          </div>
          <div>
            <h3>Téléphone</h3>
            <a
              href="tel:+15145868210"
              style={{ textDecoration: "none", color: "#ac9140" }}
            >
              (514) 586-8210
            </a>
          </div>
          <div>
            <h3>Courriel</h3>
            <a
              href="mailto:c.e.lanaudiere@outlook.com"
              style={{ textDecoration: "none", color: "#ac9140" }}
            >
              c.e.lanaudiere@outlook.com
            </a>
          </div>
        </div>
      </div>
      <div className="div_page_contact_2">
        <h2>Écrivez-nous</h2>
        <Form subject="Message" />
      </div>
    </div>
  );
}

export default PageContact;
