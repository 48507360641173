import React, { useEffect } from "react";
import "../components_styling/PageVolunteering.css";
import Form from "./Form";
import img_volunteering from "../media/images/melanie-lim-SkMBbB9gjQc-unsplash_2.jpg";
import { FiChevronDown } from "react-icons/fi";

function PageVolunteering() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //Scroll down
  function scrollDown() {
    document.getElementById("div_form").scrollIntoView();
  }

  return (
    <div className="div_page_volunteering">
      <div className="div_page_volunteering_1">
        <h2>Bénévolat divers</h2>
        <p>
          Chaque membre du Centre d’espoir de Lanaudière s’engage à avoir un
          impact positif dans la communauté de Lanaudière, ils s'engagent
          individuellement à faire une différence en mettant leurs compétences
          au service de la communauté. Le Centre d’espoir de Lanaudière offre un
          service de bénévolat divers. Nous sommes disponibles pour vous aider
          que ce soit pour vous offrir un transport, tondre la pelouse, déneiger
          votre entrée ou vous aider à des travaux.
        </p>
      </div>
      <div className="div_page_volunteering_img">
        <img src={img_volunteering} alt="volunteering.img"></img>
      </div>
      <div className="div_page_volunteering_2">
        <FiChevronDown
          id="div_page_volunteering_scroll_down_btn"
          onClick={scrollDown}
        />
      </div>

      <div className="div_page_volunteering_3">
        <h2>Demande de bénévole</h2>
        <Form subject="Demande de bénévole" />
      </div>
    </div>
  );
}

export default PageVolunteering;
