import React, { useEffect } from "react";
import "../components_styling/PageTaxClinic.css";
import Form from "./Form";
import img_tax_clinic from "../media/images/kelly-sikkema-M98NRBuzbpc-unsplash.jpg";
import { FiChevronDown } from "react-icons/fi";

function PageTaxClinic() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //Scroll down
  function scrollDown() {
    document.getElementById("div_form").scrollIntoView();
  }

  return (
    <div className="div_page_tax_clinic">
      <div className="div_page_tax_clinic_1">
        <h2>Clinique d’impôt</h2>
        <p>
          Le délai pour produire notre déclaration d’impôt arrive vite et nous
          prend souvent par surprise. Si l'on n’a pas les moyens pour contacter
          un comptable, on risque de dépasser notre délai de soumission. Le
          Centre d’espoir de Lanaudière répond à votre besoin, si vous êtes un
          citoyen à faible revenu. Chaque année, du 26 mars au 30 avril, nous
          offrons un service de clinique d’impôt, pour remplir votre déclaration
          de revenus gratuitement et l’envoyer pour vous.
        </p>
      </div>
      <div className="div_page_tax_clinic_img">
        <img src={img_tax_clinic} alt="tax_clinic.img"></img>
      </div>
      <div className="div_page_tax_clinic_2">
        <FiChevronDown
          id="div_page_tax_clinic_scroll_down_btn"
          onClick={scrollDown}
        />
      </div>

      <div className="div_page_tax_clinic_3">
        <h2>Demande pour la clinique d’impôt</h2>
        <Form subject="Demande pour la clinique d’impôt" />
      </div>
    </div>
  );
}

export default PageTaxClinic;
