import "../components_styling/NavMobile.css";
import logo from "../media/images/church_logo_2.png";
import { FiMenu, FiChevronDown, FiChevronUp } from "react-icons/fi";
import { Link, useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";

function NavMobile(props) {
  //Dropdown list animation
  const [menu, setMenu] = useState(false);

  const location = useLocation();

  useEffect(() => {
    setMenu(false);
  }, [location]);

  function Sidbar(props) {
    return (
      <div
        className={menu ? `div_nav_menu active` : `div_nav_menu`}
        id="div_nav_menu"
      >
        {menu && props.children}
      </div>
    );
  }

  function SubMenu(props) {
    const [subMenu, setSubMenu] = useState(false);

    function Chevron() {
      return subMenu ? <FiChevronUp /> : <FiChevronDown />;
    }
    return (
      <div
        className={
          subMenu && menu ? `${props.classe} active` : `${props.classe}`
        }
      >
        <div
          className={
            subMenu && menu
              ? `${props.classe}_div active`
              : `${props.classe}_div`
          }
        >
          <h2
            onClick={() => {
              setSubMenu(!subMenu);
            }}
          >
            {props.title}
          </h2>
          <Chevron />
        </div>

        {subMenu && props.children}
      </div>
    );
  }

  return (
    <div className="div_nav">
      <nav className="nav_mobile">
        <FiMenu
          id="div_nav_menu_icon"
          onClick={() => {
            setMenu(!menu);
          }}
        />
        <div className="div_nav_home">
          <Link to="/" style={{ textDecoration: "none" }}>
            <img src={logo} alt="logo" />
          </Link>
        </div>
      </nav>
      <Sidbar>
        <div className="div_nav_mission">
          <Link to="/Mission" style={{ textDecoration: "none" }}>
            <h2>Mission</h2>
          </Link>
        </div>
        <div className="div_nav_news">
          <Link to="News" style={{ textDecoration: "none" }}>
            <h2>Annonces</h2>
          </Link>
        </div>
        <div className="div_nav_choir">
          <Link to="/Choral" style={{ textDecoration: "none" }}>
            <h2>Chorale</h2>
          </Link>
        </div>
        <div className="div_nav_youth">
          <Link to="/Youth" style={{ textDecoration: "none" }}>
            <h2>Jeunesse</h2>
          </Link>
        </div>

        {/* <div className="div_nav_donation">
          <Link to="/Donation" style={{ textDecoration: "none" }}>
            <h2>Donation</h2>
          </Link>
        </div> */}

        <SubMenu classe="div_nav_service" title="Services">
          <div className="div_nav_service_items" id="div_nav_service_items">
            <Link
              to="/SpiritualHelp"
              style={{ textDecoration: "none", color: "white" }}
            >
              <h3>Accompagnement spirituel</h3>
            </Link>
            <Link
              to="/Volunteering"
              style={{ textDecoration: "none", color: "white" }}
            >
              <h3>Bénévolat</h3>
            </Link>
            <Link
              to="/Homework"
              style={{ textDecoration: "none", color: "white" }}
            >
              <h3>Aide au devoir</h3>
            </Link>
            <Link
              to="/HelpSenior"
              style={{ textDecoration: "none", color: "white" }}
            >
              {/* <h3>Aide aux ainés</h3> */}
              <h3>Visites d'amitié</h3>
            </Link>
            <Link
              to="/WalkingClub"
              style={{ textDecoration: "none", color: "white" }}
            >
              <h3>Club de marche</h3>
            </Link>
            <Link
              to="/TaxClinic"
              style={{ textDecoration: "none", color: "white" }}
            >
              <h3>Clinique d’impôt</h3>
            </Link>
          </div>
        </SubMenu>
        <SubMenu classe="div_nav_contacts" title="Nous joindre">
          <div className="div_nav_contacts_items" id="div_nav_contacts_items">
            <a
              href="https://www.youtube.com/channel/UCG4lP18rHWu-suRoWeb0QRw/featured"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none", color: "white" }}
            >
              <h3>Youtube</h3>
            </a>
{/*             <a style={{ textDecoration: "none", color: "white" }}>
              <h3>Facebook</h3>
            </a> */}
            <a
              href="https://instagram.com/_bridgeofhope_?utm_medium=copy_link"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none", color: "white" }}
            >
              <h3>Instagram</h3>
            </a>
            <Link
              to="/Contact"
              style={{ textDecoration: "none", color: "white" }}
            >
              <h3>Nous contacter</h3>
            </Link>
          </div>
        </SubMenu>
      </Sidbar>
    </div>
  );
}

export default NavMobile;
