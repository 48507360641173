import React, { useEffect } from "react";
import "../components_styling/PageHomework.css";
import Form from "./Form";
import img_school from "../media/images/pexels-andrea-piacquadio-3769981_2.jpg";
import { FiChevronDown } from "react-icons/fi";

function PageHomework() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //Scroll down
  function scrollDown() {
    document.getElementById("div_form").scrollIntoView();
  }

  return (
    <div className="div_page_homework">
      <div className="div_page_homework_1">
        <h2>Aide au devoir</h2>
        <p>
          Après une journée de travail bien remplie, la fatigue prend souvent le
          dessus et l’on manque de temps pour accompagner les enfants. La
          solution est là, le Centre d’espoir de Lanaudière met un service
          d’aide au devoir pour les jeunes afin de les aider à développer les
          compétences et les connaissances indispensables à une meilleure
          réussite scolaire.
        </p>
      </div>
      <div className="div_page_homework_img">
        <img src={img_school} alt="school.img"></img>
      </div>
      <div className="div_page_homework_2">
        <FiChevronDown
          id="div_page_homework_scroll_down_btn"
          onClick={scrollDown}
        />
      </div>

      <div className="div_page_homework_3">
        <h2>Demande d'aide au devoir</h2>
        <Form subject="Demande d'aide au devoir" />
      </div>
    </div>
  );
}

export default PageHomework;
