import React from "react";
import PropTypes from 'prop-types';
import "../components_styling/Form.css";
import emailjs from "emailjs-com";

function Form({subject}) {
  
  //Send email
  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_2z3j2hk",
        "template_1na4wyc",
        e.target,
        "user_OhUyd2w5iQWu6ka3wAiun"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  }

  return (
    <div className="div_form" id="div_form">
      <form onSubmit={sendEmail}>
        <input type="hidden" name="subject" value={subject}/>
        <input type="text" id="div_form_name" name="name" placeholder="Nom" required/>
        <input
          type="email"
          id="div_form_email"
          name="email"
          placeholder="Email"
          required
        />
        <textarea
          id="div_form_message"
          name="message"
          placeholder="Écrivez votre demande ici..."
          required
        />
        <button id="div_form_btn" type="submit">
          Soumettre
        </button>
      </form>
    </div>
  );
}

Form.propTypes = {
    subject: PropTypes.string.isRequired
}

export default Form;
