import React from 'react'
import NavDesktop from "./NavDesktop";
import NavMobile from "./NavMobile";

function Navigator() {
  // Declare a new state variable with the "useState" Hook
  const [width, setWidth] = React.useState(window.innerWidth);
  const breakpoint = 1500;

  function setDimension() {
    setWidth(window.innerWidth);
  }

  React.useEffect(() => {
    /* Inside of a "useEffect" hook add an event listener that updates
             the "width" state variable when the window size changes */
    window.addEventListener("resize", setDimension);

    /* passing an empty array as the dependencies of the effect will cause this
             effect to only run when the component mounts, and not each time it updates.
             We only want the listener to be added once */
    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, []);

  return width < breakpoint ? <NavMobile /> : <NavDesktop />;
}

export default Navigator;
