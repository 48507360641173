import "../components_styling/PageMission.css";
import img_chair from "../media/images/aaron-burden-yiI_e4zSEvo-unsplash_2.jpg";
import img_help from "../media/images/jon-tyson-rbz1hVh7_LM-unsplash.jpg";
import img_sharing from "../media/images/alexandra-fuller-5KXaEUZ3DhU-unsplash.jpg";

function PageMissionDesktop() {
  return (
    <div className="div_page_mission">
      <div className="div_page_mission_1">
        <h2>Notre mission</h2>
        <p>
          La raison d’être du Centre d’espoir de Lanaudière est d’aimer le Dieu
          créateur de l’univers, de proclamer le message des trois anges
          d'Apocalypse 14 : 6-12 et de servir son prochain. Nous sommes un
          groupe de personnes, appelées à vivre et mettre en pratique
          l’évangile éternel tel qu’il s’exprime dans le ministère de Jésus.
        </p>
      </div>
      <img
        src={img_chair}
        alt="chair.img"
        id="div_page_mission_img_chair"
      ></img>
      <div className="div_page_mission_2">
        <div className="div_page_mission_2_img">
          <img src={img_help} alt="help.img"></img>
        </div>
        <div className="div_page_mission_2_txt">
          <h2>Aider son prochain</h2>
          <p>
            Nous voulons vivre l’Évangile, en répondant aux besoins des gens de
            nos quartiers et dans notre secteur. Nous avons la mission d’œuvrer
            en faveur des autres, pour le bien des autres, à montrer aux autres
            les promesses d’espérance et de restauration que nous avons reçu en
            Jésus. Nous voulons être le sel de la terre, un agent de
            transformation. Nous voulons nous mêler aux autres afin de faire une
            différence positive dans leurs vies.
          </p>
        </div>
      </div>
      <div className="div_page_mission_3">
        <div className="div_page_mission_3_txt">
          <h2>Proclamer la bonne nouvelle</h2>
          <p>
            Nous nous donnons comme vocation de nous occuper de ceux qui nous
            entourent, en suivant la méthode du Christ, en nous mêlant aux gens,
            en témoignant de la sympathie, en les soulageant et en gagnant leur
            confiance. Nous voulons donc nous occuper de leurs besoins
            physiques, mentaux, et émotionnels, mais aussi nous occuper de leurs
            besoins spirituels et partager la bonne nouvelle du salut avec tous
            ceux qui le désirent.
          </p>
        </div>
        <div className="div_page_mission_3_img">
          <img src={img_sharing} alt="sharing.img"></img>
        </div>
      </div>
    </div>
  );
}

export default PageMissionDesktop;
